<template>
  <div>
    <div v-if="payType == 0" class="placeOder_content">
      <!-- {{addresslist}} -->
      <!-- 地址 -->
      <div class="vancard-header" @click="goAddress()">
        <div class="vancard-first">
          <span v-if="addresslist.receiver_name" style="margin-right: 20px">
            {{ addresslist.receiver_name }}
          </span>
          <span v-if="addresslist.name" style="margin-right: 20px">
            {{ addresslist.name }}
          </span>
          <span>{{ addresslist.tel }}</span>
        </div>
        <div class="vancard-second">
          <div v-if="addresslist.isDefault === 1" class="vancard-is_default">
            默认
          </div>
          <!-- <span>
            默认
          </span> -->
          <div class="vancard-address">
            {{ addresslist.province_name }}{{ addresslist.city_name }}{{ addresslist.address }}
          </div>
        </div>
        <div class="vancard-icon">
          <van-icon name="arrow" />
        </div>
      </div>
      <!--        拼团人数列表-->
      <div v-if="spellGroupNumberList && spellGroupNumberList.length > 0" class="spellGroupNumberListWrap">
        <div class="spellGroupNumberList">
          <ul class="spellGroupNumberListContent">
            <li
              v-for="(item, index) in spellGroupNumberList"
              :key="index"
              class="spellGroupNumberListContentLi1"
            >
              <div v-if="item.headpic === ''" class="spellGroupImg2">?</div>
              <div v-else class="spellGroupImg1">
                <img :src="item.headpic" alt="">
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 商品卡片 -->
      <div v-for="(item, index) in goodsList" :key="index" class="group_xxon">
        <!--            店铺名称头像-->
        <div v-if="(type !== 1 || type !== 2) && (item.imgIcon || item.name)" class="group_xxon_title">
          <p class="img">
            <img :src="item.imgIcon" alt>
          </p>
          <p class="name">{{ item.name }}</p>
        </div>
        <div v-for="items in item.data" :key="items.goodId" class="group_box">
          <div class="group_top">
            <div class="group_image">
              <img :src="items.goodCover" alt>
            </div>
            <div class="group_detail">
              <div class="group_detail_title">
                {{ items.goodName }}
              </div>
              <div class="group_detail_txt">
                {{ items.goodSpec }}
              </div>
              <div class="group_detail_lay">
                <span><i>¥</i>{{ items.vipPrice }}</span> 官网价¥{{ items.goodPrice }}
              </div>

              <div class="group_detail_buynum">
                <!-- <van-stepper v-model="items.goodNum" /> -->
                x{{ items.goodNum }}
              </div>
            </div>
          </div>
          <div
            v-if="chengzhangzhi && type != 1 && type != 2"
            class="group_bottom"
          >
            <span>赠品</span>
            <span>成长值*{{ chengzhangzhi }}</span>
            <!--            <van-icon name="arrow" />-->
          </div>
        </div>

        <div class="group_total">
          共{{ item.allCount }}件,小计
          <span
            style="color: red; font-size: 18px; font-weight: 600"
          >
            ¥{{ item.allMoney }}
          </span>元
        </div>
      </div>
      <!-- showPoint -->
      <!-- 菜单选项 -->
      <van-cell-group
        style="text-align: left; margin: 10px auto; font-size: 12px"
      >
        <van-cell title="商品总额" :value="'¥' + blanceInfolist.totelPrice" />
        <!-- <van-cell title="优惠金额" :value="'¥' + blanceInfolist.totalFreight" /> -->
        <van-cell title="运费" :value="'¥' + blanceInfolist.totalFreight" />

        <van-cell v-if="integral && integral.isHavePoint === 0" is-link>
          <template #title> 悦积分</template>

          <template>
            <span class="custom-no-value"> {{ integral.pointNum }} </span>
          </template>
        </van-cell>

        <van-cell
          v-if="integral && integral.isHavePoint === 1"
          is-link
          @click="showPointChange"
        >
          <template #title>
            悦积分
            <!-- <span class="custom-title">{{ integral.pointNum }}</span> -->
          </template>

          <template>
            <span
              :class="selectPoint.isSelect ? 'custom-value' : 'custom-no-value'"
            >
              {{ selectPoint.isSelect ? selectPoint.selectInfo.text : '去选择' }}
            </span>
          </template>
        </van-cell>

        <!-- 优惠券单元格 -->
        <van-coupon-cell
          v-if="type !== 1 && type !== 2"
          :chosen-coupon="chosenCoupon"
          :coupons="couponList"
          style="text-align: left"
          @click="showList = true"
        />

        <van-cell
          v-if="blanceInfolist.isFreeGoods === 1"
          is-link
          url="/couoderlist"
        >
          {{ blanceInfolist.freightMsg }}
        </van-cell>
      </van-cell-group>

      <!-- 优惠券列表 -->

      <van-popup
        v-if="type !== 1 && type !== 2"
        v-model="showList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px"
      >
        <van-coupon-list
          :coupons="couponList"
          :chosen-coupon="chosenCoupon"
          :show-exchange-bar="false"
          style="text-align: left"
          :disabled-coupons="disabledCoupons"
          @change="onChange"
        />
      </van-popup>

      <div class="group_Tip_box"><span /> 优惠券一经使用概不退还</div>

      <van-cell-group
        style="text-align: left; margin: 10px auto; font-size: 10px"
      >
        <!-- value="去凑单" -->
        <van-cell
          v-if="blanceInfolist.isGiftGoods === 1"
          is-link
          @click="showMarkup = !showMarkup"
        >
          {{ blanceInfolist.giftMessage }}
        </van-cell>

        <van-cell
          v-if="blanceInfolist.isReductionMoney > 0"
          class="ReductionMoneyCell"
          :title="blanceInfolist.giftMessage"
          :value="'- ¥' + blanceInfolist.isReductionMoney"
        />
      </van-cell-group>

      <!-- 超值换购弹框 -->
      <van-popup v-model="showGiftGoods" class="showGiftGoodsBox">
        <div class="showGiftGoods">
          <div class="showGiftGoodsBtn">
            <span class="leftSpan" @click="showGiftGoods = false">
              放弃机会
            </span>
            <span class="rightSpan" @click="showGiftGoodsChange()">
              去换购
            </span>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="showReductionMoney" class="showReductionMoneyBox">
        <div class="showReductionMoney">
          <div class="showReductionMoneyBtn">
            <span class="rightSpan" @click="showReductionMoneyChange()">
              立即使用
            </span>
          </div>
        </div>
      </van-popup>

      <!-- 白拿红包弹窗 -->
      <van-popup v-if="type !== 1 || type !== 2" v-model="wwwwshow">
        <div class="popRedbg">
          <div class="popRedbg_btn" @click="goCounalist()" />
        </div>
      </van-popup>
      <!-- 提交拦 -->

      <!-- {{banCprice}} -->
      <van-submit-bar
        v-if="isSale === 1"
        :loading="isDisabledSubmitBtn"
        :price="banCprice"
        button-text="提交订单"
        @submit="onSubmit"
      />

      <van-submit-bar
        v-if="isSale === 0"
        :loading="isDisabledSubmitBtn"
        :price="banCprice"
        button-text="已售罄"
        disabled
      />
    </div>
    <!--        本地生活手机视频充值-->
    <div v-if="payType === 1" class="content">
      <!--        顶部背景图-->
      <div class="top">
        <img :src="topBanner" alt="">
      </div>
      <div style="padding: 14px">
        <!--        充值账号-->
        <div class="TopUpAccountWrap">
          <div v-if="indexType === 1" class="TopUpAccountTitle">充值账号</div>
          <div class="TopUpAccountContent">
            <input
              v-model="TopUpAccount"
              class="TopUpAccount"
              disabled
              placeholder="请输入手机号码"
              type="number"
            >
          </div>
          <div v-if="indexType === 1" class="TopUpAccountRules">
            <p class="TopUpAccountRulesText">
              1.该账号为所充值视频平台的账号，请仔细核对账号信息
            </p>
            <p class="TopUpAccountRulesText">
              2.请确保充值账号无误，充值成功后不支持退换
            </p>
            <p class="TopUpAccountRule sText">3.本次充值不提供发票</p>
          </div>
          <div v-if="indexType === 2" class="TopUpAccountRules">
            <p
              v-for="(item, index) in explain"
              :key="index"
              class="TopUpAccountRulesText"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <!-- 视频面额 -->
        <div v-if="indexType === 1" class="TopUpDenominationWrap">
          <div
            v-for="(item, index) in videoList"
            :key="index"
            class="TopUpDenominationContent"
          >
            <div
              class="TopUpDenominationM"
              :class="index % 3 === 1 ? 'TopUpDenominationM_margin' : ''"
              @click="videoPay(item)"
            >
              <div
                style="padding: 20px 0 0 0"
                :class="
                  price === videoList[index].savePrice
                    ? 'TopUpDenominationContentM_v'
                    : 'TopUpDenominationContentM_vn'
                "
              >
                <div class="videoListName">{{ item.itemName }}</div>
                <div class="videoListmembers">
                  会员价：
                  <span
                    style="font-size: 17px; color: #e33f44; font-weight: 700"
                  >
                    {{ item.price }}
                  </span>元
                </div>
                <div class="videoListWebsite">
                  官网价：{{ item.officialPrice }}元
                </div>
                <div
                  :class="
                    price == videoList[index].savePrice ? 'vqSm' : 'vqsn'
                  "
                  style="
                    color: #ffffff;
                    line-height: 33px;
                    border-radius: 0 0 7px 7px;
                  "
                >
                  <p v-if="Number(price) === Number(videoList[index].savePrice)">火爆疯抢</p>
                  <p v-else>强烈推荐</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        手机面额-->
        <div v-if="indexType === 2" class="TopUpDenominationWrap">
          <p class="TopUpDenominationText">充值金额</p>
          <div class="TopUpDenominationContentM">
            <div
              v-for="(item, index) in mobileList"
              :key="index"
              :class="index % 3 === 1 ? 'TopUpDenominationM_margin' : ''"
              class="TopUpDenominationM"
              @click="TopUpDenominationSel(item)"
            >
              <div
                style="padding: 20px 0"
                :class="
                  Number(oriPrice) === Number(mobileList[index].faceValue)
                    ? 'TopUpDenominationContentM_s'
                    : 'TopUpDenominationContentM_n'
                "
              >
                <div>
                  <span style="font-size: 20px; font-weight: 500">
                    {{ item.faceValue }}
                  </span>
                  <span style="font-size: 12px">元</span>
                </div>
                <p style="font-size: 12px">售价：{{ item.price }}元</p>
              </div>
            </div>
          </div>
        </div>
        <!--        支付方式-->
        <div class="payTypeWrap">
          <div class="payType">支付方式</div>
          <div class="payTypeContent">
            <div class="payType1">
              <img
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-11/13/yuelvhuiMcMoIOBrli1605074323.png"
                alt=""
                style="width: 18px; height: 18px; margin-right: 6px"
              >
              <p style="font-size: 13px; font-weight: 500">微信支付</p>
            </div>
            <div class="payType2">
              <img
                style="width: 18px; height: 18px"
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-11/14/yuelvhui91etV0Co4E1605074854.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <!--            -->
        <div style="height: 77px" />
      </div>
      <!--        立省-->
      <div class="province">立省{{ province }}元</div>
      <!--        底部立即支付-->
      <van-row class="total">
        <van-col :span="18" class="total_cen">
          <van-row type="flex" class="cen_le">
            <van-col :span="11" class="cen_t1">合计待支付</van-col>
            <van-col
              :span="7"
              class="cen_t2"
            >
              ¥{{ buyPrice | pr_status }}
            </van-col>
            <van-col :span="6">
              <van-dropdown-menu direction="up">
                <van-dropdown-item ref="item" title="明细">
                  <div class="total_box">
                    <ul>
                      <li>
                        <span>总金额</span><span>¥{{ price | pr_status }}</span>
                      </li>
                    </ul>
                  </div>
                </van-dropdown-item>
              </van-dropdown-menu>
            </van-col>
          </van-row>
        </van-col>
        <van-col :span="6" class="tot_ok" @click="TopUpPay">立即支付</van-col>
      </van-row>
    </div>
    <!--        本地生活加油 -->
    <div v-if="payType === 2" class="content2">
      <div class="order_details">
        <div class="or_title">加油订单</div>
        <ul>
          <li>
            <span>油站名称</span><span>{{ query.gas_name }}</span>
          </li>
          <li>
            <span>油号/枪号</span><span>{{ query.name + '#' }} {{ query.gun_no }}号枪</span>
          </li>
          <li>
            <span>金额</span>
            <span>
              <span style="color: #999999; margin-right: 5px; font-size: 13px">
                约{{ query.litre }}L
              </span>
              ¥ {{ query.pay_amount }}
            </span>
          </li>
        </ul>
      </div>
      <div class="Discount order_details">
        <div class="or_title">优惠奖励</div>
        <ul class="dis_list">
          <li>
            <span>优惠直降</span><span>¥ {{ query.couponsPrice | pr_status }}</span>
          </li>
          <li>
            <span>渠道服务费</span><span>¥ {{ query.serviceFee | pr_status }}</span>
          </li>
        </ul>
      </div>
      <div class="rule" @click="rules()">
        <van-icon name="warning" color="#F7263C" size="15" />
        <span>优惠规则</span>
      </div>
      <van-row class="total">
        <van-col :span="18" class="total_cen">
          <van-row type="flex" class="cen_le">
            <van-col :span="11" class="cen_t1">合计待支付</van-col>
            <van-col
              :span="7"
              class="cen_t2"
            >
              ¥{{ query.pay_amount | pr_status }}
            </van-col>
            <van-col :span="6">
              <van-dropdown-menu direction="up" :overlay="false">
                <van-dropdown-item ref="item" title="明细">
                  <div class="total_box">
                    <ul>
                      <li>
                        <span>总金额</span><span>¥{{ query.amount_gun | pr_status }}</span>
                      </li>
                      <li>
                        <span>直降</span><span>¥{{ query.plummets | pr_status }}</span>
                      </li>
                      <li>
                        <span>优惠劵</span><span>¥{{ query.couponsPrice | pr_status }}</span>
                      </li>
                      <li>
                        <span>渠道服务费</span><span>¥{{ query.serviceFee | pr_status }}</span>
                      </li>
                    </ul>
                  </div>
                </van-dropdown-item>
              </van-dropdown-menu>
            </van-col>
          </van-row>
        </van-col>
        <van-col
          :span="6"
          class="tot_ok"
          @click="getCreateOilOrder()"
        >
          确认支付
        </van-col>
      </van-row>
    </div>
    <!--下载app二维码-->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>、
      </div>
    </div>
    <!-- 会员弹窗 -->
    <van-dialog
      v-model="isMembership"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="dia_box">
        <div class="dai_title">
          <img
            class="dialogBgc"
            src="../../assets/images/dialogBgc.png"
            alt=""
          >
          <div class="dialogText">
            <p class="dialogText1">等级不足</p>
            <p class="dialogText2">本权益为悦淘vip专享</p>
            <p class="dialogText3">需升级您的身份才可领取哦</p>
          </div>
        </div>
        <div class="dia_btn">
          <div @click="NotUpgraded">暂不升级</div>
          <div @click="ImmediateUpgrade">立即升级</div>
        </div>
      </div>
    </van-dialog>
    <van-popup
      v-model="showMarkup"
      :style="{ height: '95%' }"
      position="bottom"
      round
    >
      <div class="RedeemBox">
        <div class="RedeemTitle">
          <div class="closePop" @click="showMarkup = false">
            <img src="../../assets/images/closesku.png">
          </div>
          <h3>超值换购</h3>
          <div class="Information">
            已加购 <span>{{ GiftBigData.purchasedCount }}</span> (上限{{ GiftBigData.maxPurchasedNumber }}个)
          </div>
        </div>

        <div class="RedeemListBox">
          <mescroll :up="up" :down="down">
            <div slot="scrollList">
              <ul class="RedeemUlBox">
                <li
                  v-for="(itme, index) in RedeemList"
                  :key="index"
                  @click="gotoDetail(itme)"
                >
                  <div class="RedeemLiImg">
                    <imgLoading class="RedeemImg" :src="itme.goodsCover" />
                    <!-- <img :src="itme.goodsCover" /> -->
                  </div>
                  <div class="RedeemLiInfo">
                    <h3>{{ itme.goodsName }}</h3>

                    <div class="RedeemLiPrice">
                      <span class="vipPrice">
                        <i> ¥ </i>
                        {{ itme.vipPrice }}
                      </span>
                      <span class="goodsPrice">¥{{ itme.goodsPrice }}</span>
                      <div class="addShopCart" @click.stop="showSku(itme)">
                        加入购物车
                        <span
                          v-if="itme.purchasedCount && itme.purchasedCount > 0"
                        >
                          {{ itme.purchasedCount }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </mescroll>
        </div>

        <div class="RedeemFooterBox">
          <div
            v-if="$store.state.cartCount !== 0"
            class="RedeemCart"
            @click="redeemDefine"
          >
            <span> {{ $store.state.cartCount }}</span>

            <p>
              <img
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-17/14/yuelvhuiJc9va5fUns1594967304.png"
              >
            </p>
            <h4>购物车</h4>
          </div>

          <div class="redeemDefine" @click="redeemDefine">确定</div>
        </div>
      </div>
    </van-popup>
    <!-- 弹框 -->
    <MiniCart v-if="goodinfo" :goods-status="1" :datainfo="goodinfo" @success="addCartSuccess" />

    <!-- 积分规则 -->
    <van-popup
      v-model="showPoint"
      class="PointPop"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="PointBox">
        <div class="PointTop">
          <div class="PointTopTitle">悦积分</div>
          <div class="closePop" @click="showPoint = false">
            <img src="../../assets/images/closesku.png">
          </div>
        </div>

        <div v-if="PointDetail" class="PointContentBox">
          <div
            v-if="PointDetail && PointDetail.deductionPoint === 0"
            class="PointRadio"
          >
            {{ PointDetail.text }}
          </div>

          <div
            v-if="PointDetail && PointDetail.deductionPoint !== 0"
            class="PointRadio"
            @click="showRadioChange"
          >
            <div>
              {{ PointDetail.text }}
            </div>
            <div class="deductionBtn">
              <span v-if="showRadio">
                <img
                  src="../../assets/images/deductionBtn.png"
                >
              </span>
              <span v-else>
                <img
                  src="../../assets/images/deductionBtnNo.png"
                >
              </span>
            </div>
          </div>

          <div class="PointContentInfo">
            悦积分账户数：{{ PointDetail.availableBean }} 账户悦积分：{{ PointDetail.availableScore }}
            <span
              @click="showPop = true"
            >
              使用规则 <img src="../../assets/images/PointContentInfo.png">
            </span>
          </div>
        </div>

        <div
          v-if="PointDetail && PointDetail.deductionPoint === 0"
          class="noPointFooter"
        >
          使用
        </div>
        <div v-else class="PointFooter" @click="setPointChange">使用</div>
      </div>
    </van-popup>

    <van-popup v-model="showPop" class="showPop">
      <div class="showPopBox">
        <div class="showPopTitle">使用规则</div>
        <div
          v-if="PointDetail"
          class="showPopCentont"
          v-html="PointDetail.content"
        />

        <div class="showPopFooter" @click="showPop = false">我知道了</div>
      </div>
    </van-popup>

    <div id="aliPay" v-html="aliPayHtml" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Cell,
  CellGroup,
  Checkbox,
  Col,
  CouponCell,
  CouponList,
  Dialog,
  DropdownItem,
  DropdownMenu,
  Icon,
  Popup,
  Radio,
  RadioGroup,
  Row,
  Stepper,
  SubmitBar,
  Tag,
  Toast
} from 'vant'

Vue.use(SubmitBar).use(Toast).use(CellGroup)
  .use(Cell).use(Tag).use(Icon).use(Stepper).use(Radio).use(RadioGroup).use(CouponCell).use(CouponList).use(Popup)
  .use(Dialog).use(Col).use(Row).use(DropdownMenu).use(DropdownItem).use(Checkbox)

import {
  getProuctBlanceInfoV4,
  myPoint,
  createOrderByProductV4,
  // newMallPayV4,
  ShopcreateV4,
  getPayVersion,
  addressH5List,
  getEquityType,
  getSignUpMemberInfo,
  getProuctByPoint
} from '@/services/userApi'
import {
  // SpellGroupOrderPay,
  SpellGroupOrderSubmit,
  spellGroupOrderConfirm,
  skuChangeInfo,
  addShopCar,
  getBigGiftBigList
} from '@/services/goods.js'
import { formatNumber } from '@/utils/common/utils'
import {
  getVideoInfoData,
  getMobileListData,
  getLifeTopUpData,
  getXJComeOnSubmitOrders
} from '@/services/comeOn.js'
import mescroll from '@/component/fresh_scroll/mescroll'
import MiniCart from '@/component/GoodsDetail/MiniCart'
import imgLoading from '@/component/imgLoading'
import { getModulePaymentInfo, weChatPay } from '@/utils/payment'
import { wxConfigInit, closeShare } from '@/utils/wx-api'

export default {
  filters: {
    pr_status(v) {
      if (v === '') {
        return '0.00'
      } else {
        return formatNumber(v, 2, '.', ',')
      }
    }
  },
  components: {
    mescroll,
    MiniCart,
    imgLoading
  },
  data() {
    return {
      selectPoint: {
        isSelect: false,
        selectInfo: null
      },
      showPop: false,
      showRadio: false,
      PointDetail: null,
      showPoint: false,
      integral: null,
      showGiftGoods: false,
      showReductionMoney: false,
      goodinfo: null,
      // 下拉刷新参数说明
      down: {
        use: false, // 是否开启下拉刷新功能
        top: '84px'
      },
      // 上拉加载参数说明
      up: {
        use: true, // 是否开启上拉加载功能
        initCallBack: this.mescrollInit, // 初始化加载接口（调取初始化接口数据）

        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      RedeemList: [],
      GiftBigData: {},
      showMarkup: false,
      topclose: true,
      payType: 0,
      isSale: 0, // 是否支持发货购买
      chengzhangzhi: 0,
      radio: '1',
      chosenContactId: 0,
      value: 1,
      chosenCoupon: 0,
      showList: false,
      couponList: [], // 优惠券列表
      disabledCoupons: [], // 不可用优惠卷
      Cid: '', // 优惠券id
      couponKeyId: 0, // 优惠券id
      query: '',
      // 订单数据
      productId: '',
      productSkuId: '',
      room_id: '',
      codeNumber: '',
      live_people_id: '',
      live_id: '',
      productType: '',
      productNum: '',
      addressType: '',
      addressIds: '',
      areaId: '',
      Uid: '',
      openId: '',
      freightType: '',
      // 下单数据
      blanceInfolist: {
        totelPrice: 0,
        totalFreight: 0
      }, // 下单集合
      addresslist: '', // 收货地址
      goodsList: [], // 商品列表
      isDisabledSubmitBtn: false,
      ordersn: '',
      banCprice: 0,
      initPrice: 0, // 初始价格
      wwwwshow: false,
      // 拼团类型
      type: 999, // 1开团2拼团

      //    本地生活充值
      id: '',
      indexType: '',
      topBanner: '',
      TopUpAccount: '',
      isVideoType: true, // 视频充值选择值
      videoList: [], // 视频充值列表
      mobileList: [], // 手机充值列表
      price: '',
      oriPrice: '',
      province: 0,
      buyPrice: '',
      IndexVideoList: [],
      // 本地加油
      checked: false,
      checkedw: true,
      showimg: false,
      payFormNo: '',
      //    会员弹窗
      isMembership: false,
      //    会员弹窗背景图
      dialogBgc: '../../assets/images/dialogBgc.png',
      // showCopyLink:false,
      explain: [],
      spellGroupNumberList: 0,
      goodsId: '',
      CShopId: '',
      aliPayHtml: '',
      isLife: null
    }
  },
  computed: {
    cardType() {
      return this.chosenContactId !== null ? 'edit' : 'add'
    },
    currentContact() {
      const id = this.chosenContactId
      return id !== null ? this.list.filter((item) => item.id === id)[0] : {}
    }
  },
  watch: {
    couponList: {
      handler(n, o) {
        this.onChange(0)
      },
      deep: true
    }
  },

  created() {
    const _self = this
    const { isLife } = this.$route.query
    _self.isLife = isLife
    _self.query = this.$route.query
    _self.chengzhangzhi = this.$route.query.chengzhangzhi || 0
    _self.productId = this.$route.query.productId
    _self.CShopId = this.$route.query.CShopId
    _self.productSkuId = this.$route.query.product_sku_id
    _self.room_id = this.$route.query.roomId
    _self.robot_send = this.$route.query.robot_send
    _self.codeNumber = this.$route.query.codeNumber
    _self.live_people_id = this.$route.query.live_people_id
    _self.live_id = this.$route.query.live_id ? this.$route.query.live_id : ''
    _self.productType = this.$route.query.productType
    _self.addressIds = this.$route.query.addressIds
    _self.addressType = this.$route.query.addressType
    _self.productNum = this.$route.query.productNum
    _self.areaId = this.$route.query.areaId
    _self.freightType = this.$route.query.freightType
    _self.Uid = localStorage.getItem('uid')
    _self.openId = localStorage.getItem('openid')
    _self.type = this.$route.query.type
      ? Number(this.$route.query.type)
      : '' // 拼图类型
    //    本地生活--生活充值paytype
    _self.payType = this.$route.query.payType ? Number(this.$route.query.payType) : 0 // 0 原商品支付1本地生活充值2加油充值
    _self.indexType = this.$route.query
      ? Number(this.$route.query.indexType)
      : '' // 1视频2手机
    _self.oriPrice = Number(this.$route.query ? this.$route.query.price : '') // 对比原价，默认选中
    _self.p_id = this.$route.query ? this.$route.query.id : '' // 商品id
    _self.homeGoIndex = this.$route.query.homeGoIndex
      ? Number(this.$route.query.homeGoIndex)
      : 0 // 本地生活充值首页type
    _self.query = this.$route.query
    this.FromType = this.$route.query
      ? Number(this.$route.query.FromType)
      : Number(this.$route.query.FromType) // 手机视频充值来自哪里？0生活充值首页1本地生活首页

    if (_self.payType === 1) {
      //    手机视频充值普通会员弹窗
      const uid = window.localStorage.getItem('uid')
      this.getUserEquityType(uid)
    }
    // 小程序判断
    if (this.$route.query.env === '98') {
      if (!this.$route.query.mid) {
        this.$router.push({
          path: '/login',
          query: {
            codeNumber: this.$route.query.codeNumber
          }
        })
      }
    }
    // 分享配置
    this.$store.dispatch('getCartCount')
  },
  mounted() {
    // 拼团和其他支付的判断
    // 拼团 type1开团2拼团
    if (this.$route.query.type) {
      this.getSpellGroupDescribe()
    } else if (this.payType === 0) {
      this.getProuctBlanceInfo()
    } else if (this.payType === 1) {
      // 生活充值
      if (this.homeGoIndex !== 0) {
        this.getLifeDataList()
      } else {
        if (this.indexType === 1) {
          // 视频
          this.getVideoTopUpDetail()
        } else if (this.indexType === 2) {
          // 手机
          this.getMobileTopUpDetail()
        }
      }
    } else if (this.payType === 2) {
      // 加油
    }
  },
  methods: {
    // 点击使用积分
    setPointChange() {
      const couponInfo = this.couponList[this.chosenCoupon]
      const Price = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      if (this.showRadio) {
        this.selectPoint.selectInfo = this.PointDetail
        this.selectPoint.isSelect = this.showRadio
        this.selectPoint.selectInfo.text = this.PointDetail.text
        this.banCprice = Price - this.PointDetail.totalDeduction * 100
      } else {
        this.banCprice = Price
        this.selectPoint.isSelect = this.showRadio
      }
      this.showPoint = false
    },
    // 是否选择积分规则
    showRadioChange() {
      this.showRadio = !this.showRadio
    },
    // 获取积分规则详情
    showPointChange() {
      const couponInfo = this.couponList[this.chosenCoupon]
      const Price = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      const data = {
        mid: this.Uid,
        totalPrice: Price / 100,
        goodsList: [
          {
            goodId: this.productId,
            goodSkuId: this.productSkuId,
            activityId: '1'
          }
        ]
      }

      getProuctByPoint(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.content = res.data.content.replace(/\n/g, '<br>')
          this.PointDetail = res.data
          this.showRadio = this.selectPoint.isSelect
          this.showPoint = true
        }
      })
    },
    // 这里是悦积分的新规则接口
    myPoint() {
      const data = {
        mid: this.Uid,
        totalPrice: this.banCprice / 100,
        goodsList: [
          {
            goodId: this.productId,
            goodSkuId: this.productSkuId,
            activityId: '1'
          }
        ]
      }
      myPoint(data).then((resd) => {
        if (resd.code === 200) {
          this.integral = resd.data
        }
      })
    },
    showReductionMoneyChange() {
      this.showReductionMoney = false
    },
    showGiftGoodsChange() {
      const _that = this
      this.showGiftGoods = false
      addShopCar({
        activityId: 0,
        uid: window.localStorage.getItem('uid') || '',
        positionFrom: '0',
        productSkuId: this.productSkuId, // 商品skuId
        productId: this.productId, // 商品skuId
        // CShopId: this.CShopId, // CShopId
        CShopId: this.CShopId && this.CShopId !== '' ? this.CShopId : 0,
        joinLocation: 'collage', // 商品skuId
        productNum: this.productNum
      }).then(res => {
        this.$store.commit('SET_CARTCOINT', this.productNum)
        _that.showMarkup = true
      })
    },
    // 超值换购确定
    redeemDefine() {
      const _that = this
      this.showMarkup = false
      setTimeout(() => {
        _that.$router.push('/shopCar')
      }, 500)
    },
    addCartSuccess(data) {
      if (this.showMarkup) {
        this.getBigGiftBigList()
      }
    },
    // 调取购物车弹框
    showSku(item) {
      if (this.GiftBigData.maxPurchasedNumber <= 0) {
        Toast('换购大礼包只能填加十件')
        return
      }
      this.goodinfo = item
      this.goodinfo.goodId = item.goodsId
      this.goodinfo.skuId = item.goodsSkuId
      this.goodinfo.isCollage = 1
      this.goodinfo.isChangeGiftGoods = 1

      this.$store.commit('changeShowSku', true)
      this.$store.commit('changeShowSkuStatus', 'redemption')
    },
    // 跳转详情
    gotoDetail(item) {
      const obj = {
        product_type: 1,
        goodId: item.goodsId,
        skuId: item.goodsSkuId
      }

      this.$store.dispatch('godetail', obj)
    },
    // 超值换购初始化
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getBigGiftBigList(
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.RedeemList = []
          // 把请求到的数据添加到列表
          this.RedeemList = this.RedeemList.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            let num = 11
            if (arr.length <= 0) {
              num = 0
            }
            mescroll.endSuccess(num)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },

    getBigGiftBigList(pageNum, pageSize, successCallback, errorCallback) {
      const opt = {
        // categoryId: "8783,8797",
        uid: window.localStorage.getItem('uid'),
        page: pageNum,
        pageSize: pageSize
      }
      getBigGiftBigList(opt).then((res) => {
        if (Number(res.code) === 200) {
          this.GiftBigData = res.data
          successCallback ? successCallback(res.data.goodsList) : ''
        }
      })
    },

    // 复制跳转链接
    copyLink() {
      this.$router.push({
        path: '/JumpLink'
      })
    },
    getUserEquityType(uid) {
      const _self = this
      getEquityType({
        mid: uid
      }).then((res) => {
        if (Number(res.code) === 200) {
          // window.localStorage.setItem('memberType', res.data.memberType)
          window.localStorage.setItem('memberType', 3)
          const memberType = Number(window.localStorage.getItem('memberType'))
          // 0注册会员  1 金卡会员 2 白金会员 3 黑金会员
          if (memberType === 0) {
            // 注册会员显示会员提示弹窗
            _self.isMembership = true
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    // 暂不升级（跳转倒本地生活首页）
    NotUpgraded() {
      this.$router.push({
        path: '/homeIndex'
      })
    },
    // 立即升级（跳转倒会员升级页）
    ImmediateUpgrade() {
      this.$router.push({
        path: '/MustBuy'
      })
    },
    isclose(t) {
      this.topclose = t
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    // 进规则
    rules() {
      this.$router.push({
        path: '/PreferentialRules',
        query: {}
      })
    },
    // 加油提交订单
    getCreateOilOrder() {
      const getData = {
        gasId: this.query.gas_id, // 小桔油站ID
        gasName: this.query.gas_name, // 油站名称
        gasKeyId: this.query.id, // 中台油站ID
        oilNumberId: this.query.other_gas_id, // 油号ID
        oilName: this.query.name + '#', // 油号名称
        payAmount: this.query.pay_amount, // 付款金额
        litre: this.query.litre, // 油升数量
        amountGun: this.query.amount_gun, // 订单总金额
        payType: 1, // 支付方式 H5公众号支付传1
        gunNo: this.query.gun_no, // 枪号
        oilType: this.query.oil_type, // 燃油类型 汽油 柴油天然气
        priceGun: this.query.price_gun, // 门店枪价
        priceYfq: this.query.priceYfq, // 三方优惠枪价
        allowanceId: this.query.allowanceId // 优惠券信息
      }
      getXJComeOnSubmitOrders(getData).then((res) => {
        if (Number(res.code) === 200) {
          this.orderNo = res.data.orderNo
          this.getrePay()
        }
      })
    },
    // 加油发起支付
    getrePay() {
      getModulePaymentInfo(4, 5, {
        orderNo: this.orderNo,
        openId: this.openId
      }).then(res => {
        const params = res.data.getwayBody
        this.payFormNo = res.data.payFormNo
        this.wxPay(params)
      }).catch(err => {
        console.log(err)
      })
    },

    // 获取商品详情 调用这个借口的主要目的就是防止用户切换地址 之后 商品不更新 可购买和已售罄的状态
    getGoodsDetail(addressId) {
      this.$store.commit('changeShowLoading', 1)
      const shareId = localStorage.getItem('shareCodeNumber')
      const data = {
        shareId,
        uid: window.localStorage.getItem('uid') || 0,
        productSkuId: this.$route.query.product_sku_id,
        productType: 0,
        liveId: this.$route.query.liveid || 0,
        addressCode: addressId,
        lon: 0,
        lat: 0
      }
      skuChangeInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          this.isSale = res.data.isSale.saleState
        }
      })
    },
    // 普通商品下单
    getProuctBlanceInfo() {
      const _self = this

      const shareId = localStorage.getItem('shareCodeNumber')
      if (window.localStorage.getItem('addressItem')) {
        const ads_data = JSON.parse(window.localStorage.getItem('addressItem'))
        _self.addresslist = ads_data
        _self.areaId = ads_data.areaIds
        _self.addressIds = ads_data.id
      }
      this.getGoodsDetail(_self.areaId) // 查一下 这个地址是否允许送货 是否已经 已售罄 状态

      _self.live_people_id = this.$route.query.live_people_id
      _self.live_id = this.$route.query.live_id
        ? this.$route.query.live_id
        : ''

      const { isLife } = this.$route.query
      const params = {
        shareId,
        product_id: _self.productId,
        product_sku_id: _self.productSkuId,
        room_id: _self.room_id,
        robot_send: _self.robot_send,
        uid: _self.Uid,
        productType: _self.productType,
        product_num: _self.productNum,
        addressIds: _self.areaId,
        addressType: _self.addressType,
        areaId: _self.addressIds,
        CShopId: _self.CShopId,
        freightType: 1,
        live_people_id: this.$route.query.live_people_id,
        live_id: _self.live_id || sessionStorage.getItem('live_id')
      }
      getProuctBlanceInfoV4(params).then((res) => {
        if (Number(res.code) === 200) {
          if (window.localStorage.getItem('addressItem')) {
            const ads_data = JSON.parse(
              window.localStorage.getItem('addressItem')
            )
            _self.addresslist = ads_data
          } else {
            _self.addresslist = res.data.address
          }

          _self.goodsList = res.data.newGoodsList

          _self.blanceInfolist = res.data
          _self.wwwwshow = res.data.isFreeGoods === 1
          _self.showReductionMoney =
            res.data.isReductionMoney > 0
          // 如果在仰生活中，三周年弹框关闭
          _self.showGiftGoods = isLife && isLife === '1' ? false : res.data.isGiftGoods === 1
          _self.banCprice = (res.data.totelPrice + res.data.totalFreight) * 100
          _self.initPrice = (res.data.totelPrice + res.data.totalFreight) * 100
          _self.couponList = []
          res.data.coupon &&
          res.data.coupon.forEach((ele, index) => {
            const item = {
              available: index,
              condition: '最多优惠' + ele.amount + '元',
              reason: '',
              value: ele.amount * 100,
              name: ele.couponName,
              startAt: new Date() / 1000,
              endAt:
                new Date(ele.endtime.replace(/-/g, '/')).getTime() / 1000,
              valueDesc: ele.amount,
              unitDesc: '元',
              couponKeyId: ele.couponKeyId,
              Cid: ele.couponId
            }
            if (ele.nickName) {
              item.description = `领券团长:` + ele.nickName
            }
            _self.couponList.push(item)
          })
          this.myPoint()
        } else {
          Toast(res.msg)
          _self.$router.go(-1)
        }
      })
    },
    // get用户收货地址
    getShippingAddress() {
      const data = {
        mid: window.localStorage.getItem('uid')
      }
      addressH5List(data).then((res) => {
      })
    },
    // 拼团商品订单确认
    getSpellGroupDescribe() {
      const shareId = localStorage.getItem('shareCodeNumber')
      const _self = this
      const data = {
        shareId,
        team_id: this.$route.query.team_id, // id  '774',//
        goods_id: this.$route.query.goodsId, // 商品id '395608',
        sku_id: this.$route.query.product_sku_id, // '436936',
        uid: window.localStorage.getItem('uid'), // 用户id '1625104',
        goods_num: this.$route.query.productNum, // 商品数量 '1',
        area_id: this.addressIds, // this.$route.query.addressIds,//地址ID '703576',
        type: this.type, // 1开团2参团  '1',
        found_id: this.$route.query.found_id // 团ID'0',
      }
      spellGroupOrderConfirm(data).then((res) => {
        if (Number(res.code) === 200) {
          if (window.localStorage.getItem('addressItem')) {
            const ads_data = JSON.parse(
              window.localStorage.getItem('addressItem')
            )
            _self.addresslist = ads_data
          } else {
            _self.addresslist = res.data.areaInfo
          }
          const newProductInfo = res.data.productInfo
          const productInfo = {
            goodCover: newProductInfo.goods_img,
            goodId: newProductInfo.goods_id,
            goodName: newProductInfo.goods_name,
            goodSpec: newProductInfo.skuText,
            goodNum: newProductInfo.goods_num,
            vipPric: newProductInfo.team_price
          }

          _self.spellGroupNumberList = res.data.team // 参团列表
          if (_self.type === 1) {
            productInfo.goodPrice = newProductInfo.origin_price
          } else if (_self.type === 2) {
            productInfo.goodPrice = newProductInfo.team_price
          }
          _self.goodsList = [
            {
              allMoney: res.data.totalPrice,
              allCount: newProductInfo.goods_num,
              data: [productInfo]
            }
          ]
          _self.blanceInfolist.totelPrice = res.data.totalPrice
          _self.blanceInfolist.totalFreight = res.data.goodsreight
          _self.wwwwshow = false
          _self.banCprice = 100 * res.data.goodsTotalprice
          const addressId = _self.$route.query.areaId
          _self.getGoodsDetail(addressId)
        } else if (Number(res.code) === 400) {
          _self.$router.go(-1)
        }
      })
    },
    // 拼团商品提交订单支付订单
    spellGroupOrderBuy() {
      // const _self = this
      const data = {
        position_from: 0, // 1Banner2搜索框3胶囊位，没有就写0
        team_id: this.$route.query.team_id, // '774',
        goods_id: this.$route.query.goodsId, // 商品id '395608',
        type: this.type, // 1开团2参团 '1',
        sku_id: this.$route.query.product_sku_id, // '436936',
        area_id: this.addressIds, // this.$route.query.addressIds,//地址ID'703576',
        goods_num: this.$route.query.productNum, // 商品数量'1',
        channel: '0', // 自营
        orderFrom: '1004', // H5购买
        uid: window.localStorage.getItem('uid'), // 用户id '1625104',
        found_id: this.$route.query.found_id // 团ID 0（开团没有，参团从分享地址中获取）
      }
      getPayVersion({
        type: 1,
        payType: 1
      }).then((res1) => {
        SpellGroupOrderSubmit(data).then((res) => {
          if (Number(res.code) === 200) {
            this.$router.push({
              path: '/pay',
              query: {
                orderNo: res.data.order_sn,
                goodsId: this.productId
              }
            })
            // return

            // const params = {
            //   orderSn: res.data.order_sn,
            //   ordersn: res.data.order_sn,
            //   orderNo: res.data.order_sn, // 订单号
            //   openId: _self.openId,
            //   type: 147, // 支付类型 145支付宝 148微信 147 H5发起微信支付
            //   payVersion: res1.data.status,
            //   returnUrl:
            //     'https://web.yuetao.vip/odersky?odersn=' + res.data.order_sn
            // }
            // SpellGroupOrderPay(params).then((resData) => {
            //   if (resData.code === 200) {
            //     _self.ordersn = resData.ordersn
            //     const params = resData.pay.getwayBody
            //     _self.wxPay(params)
            //   } else {
            //     Toast(resData.msg)
            //   }
            // })
          }
        })
      })
    },
    // 普通商品下单
    createOrderByProduct() {
      const _self = this
      let _orderFrom = 1
      if (_self.live_id !== '') {
        _orderFrom = 12
      }
      const { isLife, lifeOrder, timestamp, sign, PromoterUid } = this.$route.query
      if (isLife === '1') {
        _orderFrom = 15
      }
      console.log(lifeOrder)
      const params = {
        position_from: 1,
        product_id: _self.productId,
        uid: _self.Uid,
        product_sku_id: _self.productSkuId,
        room_id: _self.room_id ? _self.room_id : '',
        robot_send: _self.robot_send ? _self.robot_send : '',
        codeNumber: _self.codeNumber ? _self.codeNumber : '',
        shareId: _self.codeNumber ? _self.codeNumber : '', // 分享人的id
        live_people_id: _self.live_people_id ? _self.live_people_id : '',
        live_id: _self.live_id ? _self.live_id : '',
        productType: _self.productType,
        product_num: _self.productNum,
        addressIds: _self.areaId,
        addressType: _self.addressType,
        areaId: _self.addressIds,
        CShopId: _self.CShopId && _self.CShopId !== '' ? _self.CShopId : 0,
        cid: _self.Cid,
        couponKeyId: _self.couponKeyId,
        type: 1,
        orderFrom: _orderFrom,
        isHadBalance: 1,
        freightType: 1,
        lifeOrder: lifeOrder ? encodeURIComponent(lifeOrder) : '',
        timestamp,
        sign
      }

      if (this.selectPoint.isSelect) {
        params.deductionScore = this.selectPoint.selectInfo.deductionScore
        params.deductionBean = this.selectPoint.selectInfo.deductionBean
        params.totalDeduction = this.selectPoint.selectInfo.totalDeduction
      } else {
        params.deductionScore = 0
        params.deductionBean = 0
        params.totalDeduction = 0
      }
      if (
        PromoterUid && PromoterUid !== 'undefined' && Number(this.$route.query.isVideoNumBuy) === 1
      ) {
        const body = {
          // PromoterUid: this.$route.query.PromoterUid,
          mid: this.$route.query.PromoterUid
        }

        getSignUpMemberInfo(body).then((resd) => {
          if (resd.code === 200) {
            params.PromoterUid = this.$route.query.PromoterUid
            params.isVideoNumBuy = this.$route.query.isVideoNumBuy
            params.sourceChannelId = resd.data.semesterId
            params.curriculumId = resd.data.channelId

            this.gotoPays(params)
          }
        })
      } else {
        this.gotoPays(params)
      }
    },
    gotoPays(params) {
      // const _self = this
      const { isLife } = this.$route.query
      createOrderByProductV4({
        ...params
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.$router.push({
            path: '/pay',
            query: {
              orderNo: res.ordersn,
              goodsId: this.productId,
              isLife
            }
          })
          // return

          // const ordersn = res.ordersn
          // getPayVersion({ type: 1 }).then((res) => {
          //   if (Number(res.code) === 200) {
          //     const version = res.data && res.data.status
          //     const _data = {
          //       ordersn: ordersn,
          //       type: 147,
          //       openId: _self.openId,
          //       payVersion: version,
          //       // 当前域名的协议 + 域名 + 访问的路由
          //       returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + ordersn
          //     }
          //     // 仰生活支付参数 --- start
          //     const { isLife } = this.$route.query
          //     if (isLife && isLife === '1') {
          //       _data.isTravelOut = 1
          //     }
          //     // 仰生活支付参数 --- end
          //     newMallPayV4(_data).then((res) => {
          //       _self.isDisabledSubmitBtn = false
          //       if (Number(res.code) === 200) {
          //         _self.ordersn = res.ordersn
          //         // let params = res.pay.getwayUrl;
          //         // window.location.replace(params);
          //         const params = res.pay.getwayBody
          //         if (isLife && isLife === '1') {
          //           _data.isTravelOut = 1
          //           console.log('仰生活支付 body ------> ', res.pay.getwayBody.htmlForm)
          //           _self.aliPayHtml = res.pay.getwayBody.htmlForm
          //           setTimeout(() => {
          //             document.forms[0].submit()
          //           }, 100)
          //         } else {
          //           _self.wxPay(params)
          //         }
          //       } else {
          //         _self.isDisabledSubmitBtn = false
          //         Toast(res.msg)
          //       }
          //     })
          //   } else {
          //     _self.isDisabledSubmitBtn = false
          //   }
          // })
        } else {
          Toast(res.msg)
        }
      })
    },

    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.isOrderType('TRADE_SUCCESS')
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.isOrderType('UNKNOWN')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.isOrderType('UNKNOWN')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    },
    isOrderType(status) {
      const that = this
      // 区分拼团订单接口携带参数和其他订单接口携带参数
      if (this.type === 1 || this.type === 2) {
        that.$router.push({
          path: '/oderSky',
          query: {
            ordersn: that.ordersn,
            // type: that.type,
            billFundsDesc: that.banCprice / 100,
            id: that.$route.query.team_id,
            status: status
          }
        })
      } else {
        that.$router.push({
          path: '/oderSky',
          query: {
            payType: 2,
            ordersn: that.ordersn,
            payFormNo: that.orderNo,
            status: status
          }
        })
      }
    },
    // 提交订单
    onSubmit() {
      const _self = this
      //  区分拼团订单和其他订单发起支付
      _self.isDisabledSubmitBtn = true
      if (this.$route.query.type === '1' || this.$route.query.type === '2') {
        _self.spellGroupOrderBuy() // 拼团type1开团2拼团
      } else {
        _self.createOrderByProduct()
      }
    },
    // 白拿凑单红包
    goCounalist() {
      const _self = this
      const params = {
        uid: _self.Uid,
        position_from: 0,
        product_id: _self.productId,
        product_sku_id: _self.productSkuId,
        product_num: 1,
        isCollage: 0
      }
      ShopcreateV4(params).then((res) => {
        if (Number(res.status) === 200) {
          _self.$router.push({ path: '/couoderlist' })
        } else {
          Toast(res.msg)
          _self.wwwwshow = false
        }
      })
    },
    // 优惠券选择
    onChange(index) {
      this.showList = false
      this.chosenCoupon = index
      const couponInfo = this.couponList[index]

      this.selectPoint.isSelect = false

      console.log(couponInfo)
      this.banCprice = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      this.Cid = couponInfo ? couponInfo.Cid : ''
      this.couponKeyId = couponInfo ? couponInfo.couponKeyId : 0
    },

    // 返回上一级
    onClickLeft: function() {
      this.$router.back(-1)
    },
    // 选地址
    goAddress() {
      this.$router.push({
        path:
          '/addressList?id=' + this.addresslist.addressId ||
          this.addresslist.address_id
      })
    },
    locationReplace(url) {
      if (history.replaceState) {
        history.replaceState(null, document.title, url)
        history.go(0)
      } else {
        location.replace(url)
      }
    },

    //    本地生活充值
    //    生活充值首页跳转
    getLifeDataList() {
      const _this = this
      getLifeTopUpData().then((res) => {
        if (Number(res.code) === 200) {
          if (_this.homeGoIndex === 1) {
            // 话费
            _this.getMobileTopUpDetail()
          } else {
            // 优酷,腾讯,爱奇艺
            _this.IndexVideoList = res.data.videoList
            _this.getVideoTopUpDetail()
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频充值详情
    getVideoTopUpDetail() {
      const _this = this
      // 首页传递数据
      if (_this.homeGoIndex === 2) {
        // 优酷
        for (let i = 0; i < _this.IndexVideoList.length; i++) {
          if (_this.IndexVideoList[i].name === '优酷会员') {
            _this.p_id = _this.IndexVideoList[i].projectId
            break
          }
        }
      } else if (_this.homeGoIndex === 3) {
        // 腾讯
        for (let i = 0; i < _this.IndexVideoList.length; i++) {
          if (_this.IndexVideoList[i].name === '腾讯会员') {
            _this.p_id = _this.IndexVideoList[i].projectId
            break
          }
        }
      } else if (_this.homeGoIndex === 4) {
        // 爱奇艺
        for (let i = 0; i < _this.IndexVideoList.length; i++) {
          if (_this.IndexVideoList[i].name === '爱奇艺会员') {
            _this.p_id = _this.IndexVideoList[i].projectId
            break
          }
        }
      } else if (_this.homeGoIndex === 0) {
        _this.p_id = _this.$route.query.id
      }
      getVideoInfoData({
        goodsId: this.p_id
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.topBanner = res.data.goodsInfo.bgImg // 头部背景图
          this.TopUpAccount = res.data.phone // 充值账号
          this.videoList = res.data.list // 面额
          this.price = res.data.list[0].sale_price // 会员价
          this.province = res.data.list[0].savePrice // 立省
          this.oriPrice = res.data.list[0].officialPrice // 原价
          this.buyPrice = res.data.list[0].buyPrice // 售价
          this.p_id = res.data.list[0].itemId
          this.goodsId = res.data.list[0].goodsId
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频充值选择
    videoPay(item) {
      this.price = item.sale_price // 会员价
      this.oriPrice = item.official_price // 原价
      this.province = item.savePrice // 立省
      this.buyPrice = item.buyPrice // 售价
      this.p_id = item.p_id // id
      this.goodsId = item.goodsId
    },
    // 手机充值详情
    getMobileTopUpDetail() {
      const that = this
      getMobileListData().then((res) => {
        if (res.code === 200) {
          this.topBanner =
            'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-10/19/yuelvhuiIEitBjzsl81605008834.png'
          this.TopUpAccount = res.data.phone
          this.mobileList = res.data.list
          this.explain = res.data.explain
          if (this.FromType === 1) {
            // 本地生活首页默认选中
            that.buyPrice = that.mobileList[0].buyPrice // 售价
            that.province = that.mobileList[0].savePrice // 立省
            that.oriPrice = that.mobileList[0].faceValue // 选中
            that.p_id = that.mobileList[0].goodsId //
          } else {
            // 生活充值首页默认选中
            for (let i = 0; i < this.mobileList.length; i++) {
              if (Number(that.mobileList[i].faceValue) === Number(that.oriPrice)) {
                that.buyPrice = that.mobileList[i].buyPrice // 售价
                that.province = that.mobileList[i].savePrice // 立省
                break
              }
            }
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    // 手机充值面额选择
    TopUpDenominationSel(item) {
      this.price = item.price // 优惠价格
      this.oriPrice = item.faceValue // 原价
      this.province = (item.faceValue - item.price).toFixed(1) // 立省
      this.buyPrice = item.price // 合计待支付
      // this.province = item.savePrice; //立省
      // this.buyPrice = item.buyPrice; //合计待支付
      this.p_id = item.goodsId
    },
    // 充值接口
    TopUpPay() {
      if (this.buyPrice === '') {
        if (this.indexType === 1) {
          Toast('请选择金额')
          return
        } else if (this.indexType === 2) {
          Toast('请选择卡片')
          return
        }
      } else {
        if (this.indexType === 1) {
          this.VideoTopUpPay()
        } else if (this.indexType === 2) {
          this.MobileTopUpPay()
        }
      }
    },
    // 视频充值支付
    VideoTopUpPay() {
      const _this = this
      getModulePaymentInfo(2, 5, {
        phone: _this.TopUpAccount,
        p_id: _this.p_id,
        returnUrl: 'https://www.yuetao.vip',
        openId: localStorage.getItem('openid'),
        payChannel: 3,
        goodsId: _this.goodsId,
        orderNo: '',
        source: 'YHLM',
        itemId: _this.p_id
      }).then(res => {
        if (Number(res.code) === 200) {
          const params = res.data.getwayBody
          _this.wxPay(params)
        } else {
          Toast(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
      // getVideoTopUpPay({
      //   mobile: _this.TopUpAccount,
      //   p_id: _this.p_id,
      //   returnUrl: "https://www.yuetao.vip",
      //   openId: localStorage.getItem("openid"),
      //   payChannel: 3,
      //   payType: 1,
      //   payVersion: 4,
      //   // order_sn:'',
      // }).then((res) => {
      //   if (Number(res.code) === 200) {
      //     let params = res.data.getwayBody;
      //     _this.wxPay(params);
      //   } else {
      //     Toast(res.msg);
      //   }
      // });
    },
    // 手机充值支付
    MobileTopUpPay() {
      const _this = this
      getModulePaymentInfo(1, 5, {
        phone: _this.TopUpAccount,
        id: _this.p_id,
        returnUrl: 'https://www.yuetao.group',
        openId: localStorage.getItem('openid'),
        payChannel: 3,
        goodsId: _this.p_id,
        orderNo: '',
        source: 'YHLM'
      }).then(res => {
        if (Number(res.code) === 200) {
          const params = res.data.getwayBody
          _this.wxPay(params)
        } else {
          if (Number(res.code) === 300) {
            this.$router.push({
              path: '/lifeServiceOrder',
              query: {
                type: 2
              }
            })
          } else {
            Toast(res.msg)
          }
        }
      }).catch(err => {
        console.log(err)
      })
      // getMobileTopUpPay({
      //   phone: _this.TopUpAccount,
      //   id: _this.p_id,
      //   returnUrl: "https://www.yuetao.group",
      //   openId: localStorage.getItem("openid"),
      //   payChannel: 3,
      //   payType: 1,
      //   payVersion: 4,
      //   // order_sn:'',//在详情页调支付的时不用传这个参数
      // }).then((res) => {
      //   if (Number(res.code) === 200) {
      //     let params = res.data.getwayBody;
      //     _this.wxPay(params);
      //   } else {
      //     if (Number(res.code) === 300) {
      //       this.$router.push({
      //         path: "/lifeServiceOrder",
      //         query: {
      //           type: 2,
      //         },
      //       });
      //     } else {
      //       Toast(res.msg);
      //     }
      //   }
      // });
    },
    //    本地生活分享
    configShare() {
      const that = this
      const shareInfo = {}
      if (that.payType === 1) {
        // 本地生活充值
        shareInfo.title =
          '话费，爱奇艺，优酷，腾讯等充值8折起，更多充值尽在悦淘APP'
        shareInfo.desc = '我在悦淘友发现，绝对适合你，速来围观～'
        shareInfo.imgUrl =
          'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-13/17/yuelvhuiJVSUWQc8hY1605260071.png'
        if (that.indexType === 1) {
          // 视频
          shareInfo.link =
            'https://web.yuetao.vip/placeOder?codeNumber=' +
            window.localStorage.getItem('recode') +
            '&payType=1&indexType=1&FromType=1'
        } else if (that.indexType === 2) {
          // 手机
          shareInfo.link =
            'https://web.yuetao.vip/placeOder?codeNumber=' +
            window.localStorage.getItem('recode') +
            '&payType=1&indexType=2&FromType=1'
        }
        wxConfigInit(shareInfo)
        // this.$store.dispatch('wxConfigInit', shareInfo)
      } else {
        closeShare() // 禁用分享
        // if (typeof WeixinJSBridge == "undefined") {
        //   if (document.addEventListener) {
        //     document.addEventListener(
        //       "WeixinJSBridgeReady",
        //       WeixinJSBridge.call("hideOptionMenu"),
        //       false
        //     );
        //   } else if (document.attachEvent) {
        //     document.attachEvent(
        //       "WeixinJSBridgeReady",
        //       WeixinJSBridge.call("hideOptionMenu")
        //     );
        //     document.attachEvent(
        //       "onWeixinJSBridgeReady",
        //       WeixinJSBridge.call("hideOptionMenu")
        //     );
        //   }
        // } else {
        //   WeixinJSBridge.call("hideOptionMenu");
        // }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell-group .van-cell__title {
  font-size: 14px !important;
  font-weight: 400;
}

/deep/ .van-cell-group .van-cell__value {
  font-size: 14px !important;
}

// /deep/ .van-popup {
//   background: rgba(0, 0, 0, 0);
// }
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.placeOder_content {
  width: 100%;
  height: auto;
  overflow: auto;
  background: #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .vancard-header {
    width: 100%;
    overflow: auto;
    position: relative;

    background: #fffef3;
    padding-top: 5px;

    .vancard-first {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      margin-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .vancard-second {
      text-align: left;
      margin-left: 15px;
      margin-bottom: 15px;
      width: 75%;
      line-height: 20px;
      overflow: hidden;

      .vancard-is_default {
        float: left;
        font-size: 12px;
        border: 1px solid red;
        color: red;
        padding: 1px 3px;
        vertical-align: middle;
        margin-right: 20px;
      }

      .vancard-address {
        float: left;
        width: 80%;
      }

      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }

    .vancard-icon {
      position: absolute;
      right: 20px;
      top: 30px;
      font-size: 18px;
    }
  }

  .vancard-header::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.05333rem;
    background: -webkit-repeating-linear-gradient(135deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background: repeating-linear-gradient(-45deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background-size: 2.13333rem;
    content: "";
  }

  .group_xxon {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 10px 0;

    .group_xxon_title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      padding-top: 10px;

      .img {
        width: 20px;
        height: 20px;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        margin-left: 10px;
      }
    }

    .group_box {
      width: 100%;
      height: auto;
      overflow: auto;
      background: #fff;

      .group_top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 135px;
        overflow: auto;

        .group_image {
          width: 100px;
          height: 100px;
          border-radius: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }

        .group_detail {
          width: 225px;
          text-align: left;
          font-size: 12px;
          padding-left: 15px;
          position: relative;
          line-height: 20px;
          overflow: auto;

          .group_detail_title {
            color: #333333;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 13px;
            padding: 0 5px;
          }

          .group_detail_txt {
            color: #999999;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .group_detail_lay {
            color: #999;
            font-size: 13px;
            font-weight: 600;

            span {
              color: #ff001b;
              font-size: 18px;

              i {
                font-size: 10px;
                font-style: normal;
                //  font-style: none;
              }
            }

            // margin-top: 30px;
          }

          .group_detail_buynum {
            position: absolute;
            bottom: 5px;
            right: 0;
          }
        }
      }

      .group_bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 10px auto;
        background: #e6e6e6;
        font-size: 13px;
        color: #999999;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }

    .group_total {
      text-align: right;
      padding-right: 25px;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
  }

  .pay_boxtype {
    text-align: left;
    background: #fff;
    // border-bottom: 100px solid #f6f6f6;
    margin-top: 20px;
    margin-bottom: 70px;

    .pay_title {
      line-height: 40px;
      padding-left: 15px;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .pay_boxtype1 {
    margin: 10px 0 10px 0;
    text-align: left;
    background: #fff;
  }

  .popRedbg {
    width: 260px;
    height: 350px;
    background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-28/22/yuelvhuiIyhyIQy5qv1595946694.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    .popRedbg_btn {
      width: 150px;
      height: 50px;
      background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-28/22/yuelvhuiyH5Fv3slDO1595946945.png") no-repeat;
      background-size: 100% 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      bottom: 5px;
      left: 50%;
    }
  }
}

.ophead {
  height: 40px;
}

.ophead.vs {
  transition: height 0.6s ease-in-out;
  height: 0;
  display: none;
}

.content {
  /*position: fixed;*/
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: "PingFang SC Regular";
  text-align: left;
  // margin-top: 40px;
}

/*    本地生活充值*/
.top {
  width: 100%;
  height: 70px;
}

.top img {
  width: 100%;
  height: 100%;
}

.TopUpAccount {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  background: none;
  padding: 20px 0 20px 0;
  font-size: 22px;
  color: #000;
  font-family: "PingFang SC Regular";
  margin-bottom: 10px;
}

input::-webkit-input-placeholder {
  color: #999;
}

.TopUpAccountRules {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 11px;
  margin-bottom: 27px;
}

.TopUpAccountRulesText {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

/*    面额*/
.TopUpDenominationWrap {
  margin-bottom: 20px;
}

.TopUpDenominationContent,
.TopUpDenominationContentM {
  display: flex;
  flex-wrap: wrap;
}

.TopUpDenominationContentM_s {
  color: #e33f44;
  background-color: #fceeee;
  border: 1px solid #e33f44;
  border-radius: 7px;
}

.TopUpDenominationContentM_n {
  border: 1px solid #cacaca;
  color: #999;
  border-radius: 7px;
}

.TopUpDenominationM,
.TopUpDenominationV {
  width: 32%;
  text-align: center;
  margin-bottom: 10px;
}

.TopUpDenominationM_margin {
  margin-left: 2%;
  margin-right: 2%;
}

/*手机充值面额*/
.TopUpDenominationText {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

/*    视频充值面额*/
.TopUpDenominationContentM_v {
  background-color: #fceeee;
  border: 1px solid #e33f44;
  border-radius: 7px;
}

.TopUpDenominationContentM_vn {
  background-color: #fff;
  border: 1px solid #e33f44;
  border-radius: 7px;
}

.vqSm {
  background-color: #e33f44;
}

.vqsn {
  background-color: #fa937b;
  border: 1px solid #fa937b;
}

.videoListName {
  font-weight: 700;
  font-size: 17px;
  color: #333;
  margin-bottom: 22px;
}

.videoListmembers {
  font-size: 11px;
  color: #e33f44;
  font-weight: 500;
}

.videoListWebsite {
  color: #e33f44;
  font-size: 11px;
  margin-bottom: 17px;
}

/*    支付方式*/
.payTypeWrap {
  font-size: 14px;
  color: #333;
  font-family: "PingFang SC Medium";
  font-weight: 700;
  /*padding-bottom: 77px;*/
}

.payType {
  margin-bottom: 26px;
}

.payTypeContent {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0 20px 0;
}

.payType1 {
  display: flex;
}

.payType2 {
  margin-left: auto;
}

.province {
  position: fixed;
  bottom: 50px;
  right: 22px;
  padding: 9px 15px;
  height: 30px;
  text-align: center;
  /*line-height: 30px;*/
  color: #fff;
  background-color: #fa937b;
  border-radius: 15px 15px 15px 0;
  z-index: 99;
}

.total {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .tot_ok {
    background-color: #f7263c;
    color: #fff;
    padding: 15px;
  }

  .total_cen {
    background-color: #ffffff;

    .cen_le {
      align-items: center;

      .cen_t1 {
        font-size: 15px;
        color: #333333;
        padding-left: 13px;
      }

      .cen_t2 {
        color: #f7263c;
        font-size: 17px;
        font-weight: 600;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
      }

      .total_box {
        padding: 20px;

        & > ul {
          & > li {
            display: flex;
            padding: 10px;
            color: #333333;

            & > span:nth-child(1) {
              flex: 12;
              text-align: left;
              font-size: 15px;
            }

            & > span:nth-child(2) {
              flex: 12;
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.content2 {
  padding: 10px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .order_details {
    border-radius: 7px;
    background-color: #ffffff;
    padding: 5px 10px;

    & > div {
      padding: 5px 0;
    }

    .or_title {
      color: #333333;
      text-align: left;
      font-weight: 500;
      border-bottom: 1px solid #eeeeee;
      font-size: 17px;
    }

    & > ul {
      & > li {
        display: flex;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #eeeeee;
        line-height: 30px;
        color: #333333;
        font-size: 13px;

        & > span:nth-child(1) {
          flex: 14;
          text-align: left;

          & > input {
            margin-left: 10px;
            font-size: 13px;
            color: #f7263c;
            font-weight: 600;
          }

          & > input::-webkit-input-placeholder {
            color: #999999;
          }
        }

        & > span:nth-child(2) {
          flex: 10;
          text-align: right;
        }
      }

      & > li:nth-last-child(1) {
        border: 0;
      }
    }
  }

  .Discount {
    margin-top: 10px;

    .dis_list {
      & > li {
        & > span:nth-child(2) {
          color: #f7263c;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }
  }

  .rule {
    margin: 10px;
    text-align: left;
    display: flex;
    align-items: center;

    & > span {
      color: #333333;
      font-size: 12px;
      padding: 2px;
    }
  }

  .payment {
    background-color: #ffffff;
    padding: 0 10px 10px;
    border-radius: 5px;

    img {
      width: 21px;
      height: 21px;
    }

    .pay_title {
      color: #333333;
      font-size: 17px;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding: 10px 0;
    }

    .pa_li {
      border-bottom: 1px solid #eeeeee;
      padding: 10px 0;
    }

    .pa_t {
      line-height: 25px;
      color: #333333;
    }

    // & > ul {
    //   & > li {
    //     border-bottom: 1px solid #eeeeee;
    //     padding: 10px 0;
    //     & > & > span {
    //       text-align: left;
    //     }
    //   }
    // }
  }

  .total {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .tot_ok {
      background-color: #f7263c;
      color: #fff;
      padding: 15px;
    }

    .total_cen {
      background-color: #ffffff;

      .cen_le {
        align-items: center;

        .cen_t1 {
          font-size: 15px;
          color: #333333;
        }

        .cen_t2 {
          color: #f7263c;
          font-size: 17px;
          font-weight: 600;
          text-align: justify;
          text-justify: newspaper;
          word-break: break-all;
        }

        .total_box {
          padding: 20px;

          & > ul {
            & > li {
              display: flex;
              color: #333333;

              & > span:nth-child(1) {
                flex: 12;
                text-align: left;
                font-size: 15px;
              }

              & > span:nth-child(2) {
                flex: 12;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

//强改
/deep/ .van-nav-bar {
  .van-icon {
    color: #000;
  }
}

/deep/ .van-cell__value {
  color: red;
}

.van-dialog {
  background: none;
}

.dia_box {
  padding: 10px;

  .codeTextStyle {
    color: #1f5dee;
    font-size: 12px;
    line-height: 15px;
    background: none;
    border: none;
  }

  .dai_title {
    padding: 10px 0;
    font-size: 17px;
    color: #333333;
    line-height: 16px;
    position: relative;
    text-align: center;

    .dialogBgc {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .dialogText {
      margin-top: 101px;

      .dialogText1 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 17px;
      }

      .dialogText2,
      .dialogText3 {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }

      .dialogText3 {
        margin-bottom: 9px;
      }
    }
  }

  .dia_tips {
    margin: 10px 0;
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    & > b {
      color: #1f83ee;
    }
  }

  .dia_list {
    text-align: left;

    li {
      padding: 4px 30px;
      font-size: 13px;
      line-height: 18px;

      span {
        display: inline-block;
        width: 65px;
        text-align: left;
      }
    }
  }

  .dia_btn {
    display: flex;
    /*margin: 13px 0 10px;*/

    div {
      flex: 11;
      font-size: 16px;
      font-weight: 500;
      padding: 15px;
      background-color: #fff;
    }

    & > div:nth-child(1) {
      border-radius: 0 0 0 6px;
      color: #333333;
    }

    & > div:nth-child(2) {
      color: #e1c182;
      border-radius: 0 0 6px 0;
    }
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          display: inline-block;
          margin-right: 12px;
          margin-bottom: 12px;
          float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }
}

/*    下载APP页面*/
.masks {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          display: inline-block;
          margin-right: 12px;
          margin-bottom: 12px;
          float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }

  /*    复制链接下载*/

  .showCopyLinkContent {
    position: absolute;
    height: auto;
    left: 50%;
    margin-left: -130px;
    top: 50%;
    margin-top: -200px;

    .showCopyLinkImg {
      width: 260px;
      height: 326px;
    }

    .showCopyLinkClose {
      width: 31px;
      height: 31px;
      margin-top: 29px;
    }
  }
}

.group_Tip_box1 {
  text-align: left;
  color: #999;
  padding: 0 10px;
  margin-bottom: 70px;

  span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #999;
    display: inline-block;
  }
}

.group_Tip_box {
  text-align: left;
  color: #999;
  padding: 0 10px;

  span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #999;
    display: inline-block;
  }
}

.van-cell-group {
  .van-cell__title {
    font-size: 10px;
  }

  .van-cell__value {
    font-size: 10px;
  }
}

.RedeemBox {
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;

  .RedeemListBox {
    width: 100%;
    height: 84%;

    .RedeemUlBox {
      text-align: left;

      li {
        width: 100%;
        height: 110px;
        border-bottom: 1px solid #ececec;
        padding: 15px;
        overflow: hidden;
        display: flex;

        .RedeemLiImg {
          width: 80px;
          height: 80px;
          overflow: hidden;
          float: left;
          margin-right: 10px;
          flex: 0.25;

          .RedeemImg {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            overflow: hidden;

            img {
              width: 80px;
              height: auto;
              border-radius: 10px;
            }
          }
        }

        .RedeemLiInfo {
          flex: 0.75;
          float: left;
          position: relative;
          // width: 100%;
          .RedeemLiPrice {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 30px;

            .vipPrice {
              color: red;
              font-size: 18px;

              i {
                font-size: 10px;
              }
            }

            .goodsPrice {
              color: #908f8f;
              text-decoration: line-through;
            }

            .addShopCart {
              position: absolute;
              font-size: 10px;
              border-radius: 5px;
              border: 1px solid red;
              color: red;
              right: 0;
              top: 0;
              padding: 3px 5px;

              span {
                width: 15px;
                height: 15px;
                line-height: 17px;
                border-radius: 50%;
                background: red;
                color: #fff;
                position: absolute;
                right: -5px;
                top: -5px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    // background: #000;
  }

  .RedeemTitle {
    position: relative;
    height: 50px;
    padding: 10px 20px;
    overflow: hidden;
    border-bottom: 1px solid #ececec;

    h3 {
      float: left;
      width: 40%;
      color: #000;
      text-align: left;
      font-weight: bold;
    }

    .Information {
      width: 60%;
      float: right;
      text-align: right;
      padding-right: 40px;
      color: #ccc;
      font-size: 12px;
      line-height: 20px;

      span {
        color: red;
      }
    }
  }

  .RedeemFooterBox {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid #ececec;
    display: flex;
    padding: 8px 10px;

    .RedeemCart {
      flex: 0.3;
      font-size: 12px;
      position: relative;

      span {
        position: absolute;
        background: red;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 10px;
        transform: scale(0.9);
        color: #fff;
        position: absolute;
        top: -5px;
        right: 31px;
      }

      p {
        img {
          width: 20px;
        }
      }
    }

    .redeemDefine {
      flex: 0.7;
      width: 100%;
      height: 35px;
      background: red;
      border-radius: 20px;
      color: #fff;
      line-height: 35px;
    }
  }
}

.closePop {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 15px;

  img {
    width: 15px;
    height: 15px;
  }
}

.showGiftGoods {
  width: 272px;
  height: 360px;
  background: url("../../assets/images/showGiftGoods.png") no-repeat;
  background-size: 100%;
  position: relative;

  .showGiftGoodsBtn {
    height: 43px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    // background: #000;
    span {
      height: 43px;
      width: 40%;
      margin: 0 10px;
      display: inline-block;
      line-height: 43px;
      border-radius: 22px;
      font-size: 16px;
      font-weight: bold;
    }

    .leftSpan {
      border: 1px solid #ffdc99;
      color: #ffdc99;
    }

    .rightSpan {
      background: #efb841;
      box-shadow: 0px 3px 10px 0px rgba(174, 38, 39, 0.49);
      color: #d13031;
    }
  }
}

.showReductionMoney {
  width: 272px;
  height: 360px;
  background: url("../../assets/images/ReductionMoney.png") no-repeat;
  background-size: 100%;
  position: relative;

  .showReductionMoneyBtn {
    height: 43px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    // background: #000;
    span {
      height: 43px;
      width: 50%;
      margin: 0 10px;
      display: inline-block;
      line-height: 43px;
      border-radius: 22px;
      font-size: 16px;
      font-weight: bold;
    }

    .leftSpan {
      border: 1px solid #ffdc99;
      color: #ffdc99;
    }

    .rightSpan {
      background: #efb841;
      box-shadow: 0px 3px 10px 0px rgba(174, 38, 39, 0.49);
      color: #d13031;
    }
  }
}

.showReductionMoneyBox {
  background: transparent;
}

.showGiftGoodsBox {
  background: transparent;
}

.ReductionMoneyCell {
  .van-cell__title {
    flex: 0.8;
    color: red;
  }

  .van-cell__value {
    flex: 0.2;
  }
}

.custom-title {
  color: #969799;
}

.custom-no-value {
  color: #969799;
}

.custom-value {
  color: red;
}

/deep/ .PointPop {
  /deep/ .van-popup {
    background: #fff !important;
  }
}

.PointBox {
  background: #fff;
  position: relative;
  height: 100%;

  .PointTop {
    padding: 10px 0;
    height: 50px;
    position: relative;

    .PointTopTitle {
      line-height: 30px;
      font-weight: bold;
    }
  }

  .PointContentBox {
    padding: 0 20px;
    text-align: left;
  }

  .PointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #f7263c;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }

  .noPointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #ccc;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}

.PointRadio {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-weight: bold;
  position: relative;

  .deductionBtn {
    position: absolute;
    right: 0;
    top: 10px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.PointContentInfo {
  padding: 10px 0;
  font-weight: 400;
  color: #666666;

  span {
    color: #f7263c;
    margin-left: 15px;

    img {
      width: 15px;
      height: 15px;
      vertical-align: -2px;
    }
  }
}

.showPop {
  width: 90%;
  height: 240px;
  border-radius: 10px;
  padding: 10px;

  .showPopBox {
    width: 100%;
    height: 100%;
    position: relative;

    .showPopTitle {
      font-weight: bold;
    }

    .showPopCentont {
      padding: 10px 10px;
      text-align: left;
      line-height: 24px;
      font-size: 13px;
      font-weight: 400;
    }

    .showPopFooter {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      height: 40px;
      background: #f7263c;
      color: #fff;
      border-radius: 10px;
      line-height: 40px;
    }
  }
}

.spellGroupNumberListWrap {
  width: 100%;
  background-color: #fff;
  padding: 10px 10px;
}

/*拼团人员头像列表*/
.spellGroupNumberList {
  width: 100%;
}

.spellGroupNumberListContent {
  margin: 0 auto;

  // display: flex;
  // flex: 6;
}

.spellGroupNumberListContentLi1 {
  display: inline-block;
  // flex: 1;
  vertical-align: middle;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  margin-right: 10px;
}

.spellGroupNumberListContentLi2 {
  margin-right: 0px;
}

.spellGroupImg1 {
  width: 43px;
  height: 43px;
  border: 1px solid #f7263c;
  border-radius: 50%;
}

.spellGroupImg1 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.spellGroupImg2 {
  width: 43px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  font-size: 24px;
  color: #ededed;
  font-weight: 700;
  border-radius: 50%;
  border: 1px solid #f7263c;
  background-color: #ffffff;
}

</style>
